/* 폰트 파일을 public/asset/fonts 폴더에서 가져오기 */

/* GmarketSans Font */
@font-face {
  font-family: 'GmarketSansBold';
  src: url('asset/fonts/GmarketSansTTFBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSansLight';
  src: url('asset/fonts/GmarketSansTTFLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('asset/fonts/GmarketSansTTFMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Pretendard Font */

@font-face {
  font-family: 'Pretendard-Black';
  src: url('asset/fonts/Pretendard-Black.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url('asset/fonts/Pretendard-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-ExtraBold';
  src: url('asset/fonts/Pretendard-ExtraBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-ExtraLight';
  src: url('asset/fonts/Pretendard-ExtraLight.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Light';
  src: url('asset/fonts/Pretendard-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Medium';
  src: url('asset/fonts/Pretendard-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('asset/fonts/Pretendard-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html{
  /* background: linear-gradient(to bottom, #ffffff, #eaecf8) fixed !important; */
  height: 95vh;
}

.App{
  text-align: center;
  font-family: "GmarketSans";
  background-color: #fff;
  margin:0 auto;
  /* max-width: 800px;  */
  height: 95vh;
  
  /* border:0.5px solid #EAE7EC; */
  box-sizing: border-box; /* border와 padding을 width에 포함시킴 */
  /* overflow-y: scroll; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.recommendBox{
  max-width:50%;
}

@media (min-width: 1024px) {
  .App {
    max-width: 1000px; 
    /* height:100vh; */
     /* border: 1px solid #eee;  */
     /* box-shadow: 1px 1px 0px #ddd;   */
     background-color:transparent;
    
  }

  .recommendBox{
    max-width:80%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 추가된 CSS */
p{
  margin-top : 2px !important;
  margin-bottom: 2px !important;;
  text-align: left;;
}

a {
  text-decoration: none;
  color : '#6574CD';
  font-weight:700;
}

.message-content {
  font-size:12px;
}